import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-dates/initialize';

import DateRangePicker from 'components/Elements/DateRangePicker';
import LocationSelect from 'components/Elements/LocationSelect';
import Navigation from 'modules/navigation/app';
import ReactOnRails from 'react-on-rails';

ReactOnRails.register({
  LocationSelect,
  Navigation,
  DateRangePicker,
});
